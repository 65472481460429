
.row-pairing {
  align-items: center;
}

.wright-coefficient {
  margin: 0 auto;

  width: 200px;
  height: 80px;
  display: grid;
  place-items: center;

  font-size: 16pt;
  font-weight: bold;

  border: transparent 6px dotted;
  background: #eee;
  border-radius: 12px;
  transition: border 0.3s ease;

  &.left-ready {
    border-left-color: #AED581;
  }

  &.right-ready {
    border-right-color: #AED581;
  }

  &.left-ready.right-ready {
    border-color: #AED581;
  }
}

