
.multi-occurrence {
  .list {
    display: flex;
    flex-wrap: wrap;

    margin-right: -1em;

    .beast {
      margin-right: 1em;
      min-width: 320px;
    }
  }
}
