
.ancestry-tree {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(var(--display-levels), 1fr);

  .tree-node {
    grid-column: calc(var(--tree-level) + 1);
    grid-row: span var(--row-span);

    display: flex;
    flex-direction: row;

    &::before {
      content: '';
      display: block;
      width: 0.2em;
      background: var(--relation-color);
    }

    &.relation-mother {
      --relation-color: #{mix(red, white, 10%)};
    }

    &.relation-father {
      --relation-color: #{mix(blue, white, 10%)};
    }

    border: 1px solid gray;

    &:not(:first-child) {
      border-left: 0;
    }
  }

  .beast-info {
    padding: 4px;

    display: flex;
    flex-direction: column;


  }
}
